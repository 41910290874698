import { CalendarIcon } from '@chakra-ui/icons';
import {
    Collapse,
    Flex,
    FormControl,
    Icon,
    Input,
    InputGroup,
    InputRightElement
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { RangeFocus } from 'react-date-range';
import { AiOutlineSwapRight } from 'react-icons/ai';
import { IDateRanges } from './DatePicker2';

interface DateRangeInputsProps {
    value: IDateRanges;
    onChange: (range: IDateRanges) => void;
    focusedRange: RangeFocus;
    setFocusedRange: (range: RangeFocus) => void;
    rangeColors?: string[];
}

const DateRangeInputs: React.FC<DateRangeInputsProps> = ({
    value,
    focusedRange,
    setFocusedRange
}) => {
    return (
        <Flex flexDir="column" w="100%" justifyContent="center">
            <Flex gap="0.5rem" alignItems="center" justifyContent="center">
                <FormControl maxW="130px">
                    <InputGroup size="xs">
                        <Input
                            cursor="pointer"
                            variant="editor"
                            background="datePicker.primaryRange.300"
                            borderColor={
                                focusedRange[0] === 0 && focusedRange[1] === 0
                                    ? 'datePicker.primaryRange.100 !important'
                                    : 'datePicker.gray.100'
                            }
                            color="datePicker.gray.300"
                            fontFamily="Noto Sans"
                            fontWeight="400"
                            textAlign="center"
                            value={format(value.default.startDate, 'dd.MM.yyyy')}
                            onClick={() => setFocusedRange([0, 0])}
                            readOnly
                        />
                        <InputRightElement>
                            <CalendarIcon />
                        </InputRightElement>
                    </InputGroup>
                </FormControl>
                <Flex justifyContent="center" alignItems="center">
                    <Icon as={AiOutlineSwapRight} fontSize="20px" />
                </Flex>

                <FormControl maxW="130px">
                    <InputGroup size="xs">
                        <Input
                            cursor="pointer"
                            variant="editor"
                            background="datePicker.primaryRange.300"
                            borderColor={
                                focusedRange[0] === 0 && focusedRange[1] === 1
                                    ? 'datePicker.primaryRange.100 !important'
                                    : 'datePicker.gray.100'
                            }
                            color="datePicker.gray.300"
                            fontFamily="Noto Sans"
                            fontWeight="400"
                            textAlign="center"
                            value={format(value.default.endDate, 'dd.MM.yyyy')}
                            onClick={() => setFocusedRange([0, 1])}
                            readOnly
                        />
                        <InputRightElement>
                            <CalendarIcon />
                        </InputRightElement>
                    </InputGroup>
                </FormControl>
            </Flex>

            <Collapse in={Boolean(value.compare)} unmountOnExit>
                <Flex
                    gap="0.5rem"
                    alignItems="center"
                    mt="0.75rem"
                    justifyContent="center"
                >
                    <FormControl maxW="130px">
                        <InputGroup size="xs">
                            <Input
                                cursor="pointer"
                                variant="editor"
                                background="datePicker.secondaryRange.300"
                                borderColor={
                                    focusedRange[0] === 1 && focusedRange[1] === 0
                                        ? 'datePicker.secondaryRange.100 !important'
                                        : 'datePicker.gray.100'
                                }
                                color="datePicker.gray.300"
                                fontFamily="Noto Sans"
                                fontWeight="400"
                                textAlign="center"
                                value={
                                    value.compare?.startDate
                                        ? format(value.compare?.startDate, 'dd.MM.yyyy')
                                        : ''
                                }
                                onClick={() => setFocusedRange([1, 0])}
                                readOnly
                            />
                            <InputRightElement>
                                <CalendarIcon />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    <Flex justifyContent="center" alignItems="center">
                        <Icon as={AiOutlineSwapRight} fontSize="20px" />
                    </Flex>
                    <FormControl maxW="130px">
                        <InputGroup size="xs">
                            <Input
                                cursor="pointer"
                                variant="editor"
                                background="datePicker.secondaryRange.300"
                                borderColor={
                                    focusedRange[0] === 1 && focusedRange[1] === 1
                                        ? 'datePicker.secondaryRange.100 !important'
                                        : 'datePicker.gray.100'
                                }
                                color="datePicker.gray.300"
                                fontFamily="Noto Sans"
                                fontWeight="400"
                                textAlign="center"
                                value={
                                    value.compare?.endDate
                                        ? format(value.compare?.endDate, 'dd.MM.yyyy')
                                        : ''
                                }
                                onClick={() => setFocusedRange([1, 1])}
                                readOnly
                            />
                            <InputRightElement>
                                <CalendarIcon />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                </Flex>
            </Collapse>
        </Flex>
    );
};

export default DateRangeInputs;
